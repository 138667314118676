import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Footer from 'components/Footer'
import PortfolioHeader from 'components/PortfolioHeader'

import annotationComment1 from 'images/creatively/annotation-comment-1.png'
import annotationComment2 from 'images/creatively/annotation-comment-2.png'

import BackgroundSection from 'components/BackgroundSection'

const Creatively = () => {
  const {
    desktopHome,
    cLogo,
    profileMobile,
    search,
    annotation1,
    annotation2,
    pbjLive,
    spiritune,
    moeAssist,
    connectApp1,
    connectApp2,
    connectApp3,
    bts1,
    bts2,
    bts3,
    headerImage,
  } = useStaticQuery(
    graphql`
      query {
        desktopHome: file(
          relativePath: { eq: "creatively/creatively-home.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cLogo: file(relativePath: { eq: "creatively/C.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        profileMobile: file(
          relativePath: { eq: "creatively/profile-mobile.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        search: file(relativePath: { eq: "creatively/search.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        annotation1: file(relativePath: { eq: "creatively/annotation-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        annotation2: file(relativePath: { eq: "creatively/annotation-2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        spiritune: file(relativePath: { eq: "spiritune-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbjLive: file(relativePath: { eq: "pbj-live-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        moeAssist: file(relativePath: { eq: "moe-assists-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        connectApp1: file(
          relativePath: { eq: "creatively/connect-app-1.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        connectApp2: file(
          relativePath: { eq: "creatively/connect-app-2.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        connectApp3: file(
          relativePath: { eq: "creatively/connect-app-3.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bts1: file(relativePath: { eq: "creatively/bts-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bts2: file(relativePath: { eq: "creatively/bts-2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bts3: file(relativePath: { eq: "creatively/bts-3.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerImage: file(relativePath: { eq: "creatively-app-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  const connectPreviewImages = [connectApp1, connectApp2, connectApp3]
  const btsPreviewImages = [bts1, bts2, bts3]

  return (
    <Layout light>
      <SEO />
      <PortfolioHeader
        heading="The new platform for the creative community"
        image={headerImage}
        singleImage
        containerClassName="creatively"
      />
      <div className="portfolio-wrapper">
        <div className="container">
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading">
                Connecting the creative community.
              </h4>
              <p className="portfolio-section-header-description">
                Creatively champions and connects the world’s most valued
                creative community through best-in-class portfolios,
                collaborative features, and opportunities around the globe.
              </p>
            </div>
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={desktopHome}
              className="creatively__desktop-preview --home"
            />
          </section>
          <section className="creatively__app">
            <div data-sal="zoom-in">
              <BackgroundSection
                image={cLogo}
                className="creatively__app-grid --C"
              />
            </div>
            <div data-sal="slide-left">
              <BackgroundSection
                image={profileMobile}
                className="creatively__app-grid --profile-mobile"
              />
            </div>
          </section>
          <section>
            <BackgroundSection
              imageList={connectPreviewImages}
              className="creatively_app-preview --connect"
              isMultiBackground
            />
          </section>
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading --full">
                Build a beautiful portfolio with powerful tools.
              </h4>
              <p className="portfolio-section-header-description">
                High-res image and video assets displayed exactly as intended
                and rearranged with ease. Add annotations to explain your
                creative process and nest albums to create a more interactive
                user experience.
              </p>
            </div>
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={search}
              className="creatively__desktop-preview --search"
            />
          </section>
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading --full">
                Discover the best creative talent for your project.
              </h4>
              <p className="portfolio-section-header-description">
                Powerful search tools allow hiring managers and their team to
                quickly find top talent, sift through their verified work, and
                contact mutual connections for referrences.
              </p>
            </div>
          </section>
          <section className="creatively__app">
            <BackgroundSection
              image={annotation1}
              className="creatively__app-grid --annotation1"
              data-sal="zoom-out"
            >
              <img
                src={annotationComment1}
                data-sal="zoom-in"
                alt="annotation comment"
              />
            </BackgroundSection>
            <BackgroundSection
              image={annotation2}
              className="creatively__app-grid --annotation2"
              data-sal="zoom-out"
            >
              <img
                src={annotationComment2}
                data-sal="zoom-in"
                alt="annotation comment"
              />
            </BackgroundSection>
          </section>
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading --full">
                Collaborate, connect, and share.
              </h4>
              <p className="portfolio-section-header-description">
                Creatively is an active space designed for interaction.
                Creatives are encouraged to add collaborators to build a shared
                body of work with their peers.
              </p>
            </div>
          </section>
          <section>
            <BackgroundSection
              imageList={btsPreviewImages}
              className="creatively_app-preview --bts"
              isMultiBackground
            />
          </section>
          <section className="spiritune-app-navigation-footer">
            <h4 className="spiritune-app-navigation-footer-main-heading">
              See what else we’re building
            </h4>
            <div className="spiritune-app-navigation-footer-grid">
              <Link to="/portfolio/moe-assist">
                <BackgroundSection
                  image={moeAssist}
                  className="spiritune-app-navigation-footer-moe-assist"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Moe Assist
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    A management tool for influenecers
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/spiritune">
                <BackgroundSection
                  image={spiritune}
                  className="spiritune-app-navigation-footer-spiritune"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Spiritune
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Managing emotional well-being through music
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/pbj-live">
                <BackgroundSection
                  image={pbjLive}
                  className="spiritune-app-navigation-footer-pbj-live"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    PBJ Live
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Your content - wherever, whenever
                  </h4>
                </BackgroundSection>
              </Link>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Creatively
